import React from 'react';

function Footer() {
    return (
        <div>
            <footer class="text-center p-2">2023 UNO(仮). All Rights Reserved</footer>
        </div>
    );
}

export default Footer;