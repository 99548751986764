import React from 'react';

const About = () => {
    return (
        <div>
            <h2>About</h2>
            <p>このサイトでは知能情報コースの講義情報や講義のメモ、おすすめ講義などを発信していきます</p>
        </div>
    );
};

export default About;